<template>
  <div>
    <div class="row page-title-header">
      <div class="col-12">
        <div class="page-header">
          <b-breadcrumb class="m-0">
            <b-breadcrumb-item
              :to="{
                name: 'DashboardHome',
              }"
            >
              <i class="fa fa-home"></i>
            </b-breadcrumb-item>

            <b-breadcrumb-item
              :to="{
                name: 'CoreGroupbuyingsListV2',
              }"
            >團購管理
            </b-breadcrumb-item
            >
            <b-breadcrumb-item active>團購列表</b-breadcrumb-item>
          </b-breadcrumb>
        </div>
      </div>
    </div>

    <b-card>
      <b-overlay :show="showLoading" rounded="sm">
        <div class="row mb-2 align-items-center">
          <div class="col-12 col-xl-2">
            <h4 class="font-weight-bold">團購列表</h4>
          </div>
        </div>
        <div class="row">
          <div class="col-4">
            <b-form-group :disabled="readonly">
              <b-form-group
                label-cols="12"
                label-cols-lg="2"
                label-size="m"
                content-cols="12"
                content-cols-lg="6"
                label="商品名稱"
              >
                <b-form-input v-model="product.title"></b-form-input>
              </b-form-group>
              <b-form-group
                label-cols="12"
                label-cols-lg="2"
                label-size="m"
                content-cols="12"
                content-cols-lg="6"
                label="商品規格"
              >
                <b-form-input v-model="product.skus[0].sku_name"></b-form-input>
              </b-form-group>
              <b-form-group
                label-cols="6"
                label-cols-lg="2"
                label-size="sm"
                label="商品說明"
                label-for="product_description"
              >
                <b-form-textarea
                  id="title"
                  class="mb-2 mr-sm-2 mb-sm-0 col-12 col-xl-6"
                  v-model="product.description"
                ></b-form-textarea>
              </b-form-group>
              <b-form-group
                label-cols="12"
                label-cols-lg="2"
                label-size="m"
                content-cols="12"
                content-cols-lg="6"
                label="商品店內碼(選填)"
              >
                <b-form-input v-model="product.skus[0].sku_outer_code"></b-form-input>
              </b-form-group>
              <b-form-group
                label-cols="12"
                label-cols-lg="2"
                label-size="m"
                content-cols="12"
                content-cols-lg="6"
                label="商品條碼(選填)"
              >
                <b-form-input v-model="product.skus[0].sku_serial_number"></b-form-input>
              </b-form-group>
            </b-form-group>
          </div>
        </div>
        <div class="d-flex justify-content-center" v-if="!readonly" style="margin-top: 80px">
          <b-button class="mr-4" variant="outline-danger" @click="handleCancel">
            <template>返回</template>
          </b-button>
          <b-button variant="success" @click="handleSubmit">
            <template v-if="isEdit">確認編輯</template>
            <template v-if="isCreate">確認新增</template>
          </b-button>
        </div>
      </b-overlay>
    </b-card>
  </div>
</template>

<script>
import productApi from "../../../apis/product";
import {zh} from "vuejs-datepicker/dist/locale";
import {mapState} from "vuex";
import {format, formatISO, set} from "date-fns";


export default {
  data() {
    return {
      zh,
      showLoading: false,
      product: {
        title: null,
        description: null,
        merchant_id: null,
        available_start_at: {
          date: new Date(),
          time: {
            HH: "23",
            mm: "59",
          },
        },
        available_end_at: {
          date: new Date(),
          time: {
            HH: "23",
            mm: "59",
          },
        },
        is_closed: 0,
        is_enabled: 1,
        type: 'groupbuying',
        original_price: 0,
        suggest_price: 0,
        price: 0,
        need_create_sale_page_code: true,
        skus: [
          {
            sku_name: null,
            sku_outer_code: null,
            sku_serial_number: null,
            quantity: 1,
            once_quantity: 1,
            once_increase_quantity: 1,
            safety_quantity: 1,
            carton_quantity: 1,
            original_price: 0,
            suggest_price: 0,
            price: 0,
            once_max_quantity: 1,
          }
        ]
      },
    };
  },
  computed: {
    ...mapState("general", {
      organization: (state) => state.organization,
    }),
    readonly() {
      return this.$route.name === "CoreGroupbuyingsProductDetail"
    },
    isEdit() {
      return this.$route.name === "CoreGroupbuyingsProductEdit"
    },
    isCreate() {
      return this.$route.name === "CoreGroupbuyingsProductCreate"
    }
  },
  mounted() {
    if (!this.isCreate) {
      this.fetchProduct()
    }

    if (this.isCreate && this.$route.query.merchant_id) {
      this.product.merchant_id = this.$route.query.merchant_id
    }
  },
  methods: {
    format,
    async fetchProduct() {
      this.showLoading = true;

      try {
        const {data} = await productApi.getProduct(this.$route.params.product_id);
        this.product = data.data;

      } catch (error) {
        console.error(error);
        alert("取得群商品料錯誤");
      }
      this.showLoading = false;
    },
    handleCancel() {
      this.$router.push({name: "ProductList"});
    },
    async handleSubmit() {
      this.showLoading = true;


      if (this.isEdit) {
        await this.goUpdate()
      }
      if (this.isCreate) {
        await this.goCreate()
      }

      this.showLoading = false;
    },
    async goUpdate() {
      try {
        await productApi.updateProduct(this.product.id, this.product);
        this.$swal
          .fire({
            icon: "success",
            text: "更新成功",
          })
          .then(() => {
            this.$router.push({name: "CoreGroupbuyingsProductList"});
          });
      } catch (error) {
        console.error(error);
      }
    },
    async goCreate() {
      try {
        this.product.available_start_at = this.formatToDatetimeString(this.product.available_start_at)
        this.product.available_end_at = this.formatToDatetimeString(this.product.available_end_at)
        await productApi.createProduct(this.product);
        this.$swal
          .fire({
            icon: "success",
            text: "更新成功",
          })
          .then(() => {
            const params = {name: "CoreGroupbuyingsProductList"};
            if (this.$route.query.merchant_id) params.query = {merchant_id: this.$route.query.merchant_id}
            this.$router.push(params);
          });
      } catch (error) {
        console.error(error);
      }
    },
    formatToDatetimeString(datetime) {
      return formatISO(
        set(new Date(datetime.date), {
            hours: Number(datetime.time.HH),
            minutes: Number(datetime.time.mm),
            seconds: 0,
          },
        )
      )
    },
  },
};
</script>
